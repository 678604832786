import Bluebird from 'bluebird'
import {
  cloneDeepWith,
  each,
  includes,
  isEqual,
  mapValues,
} from '@technically/lodash'

const cancelOldLoaders = (prev, next) => {
  each(prev, (prevLoader) => {
    if (!includes(next, prevLoader)) {
      if (prevLoader.isPending()) {
        prevLoader.cancel()
      }
    }
  })
}

const inspectPromise = (promise) => ({
  url: promise.url,
  isCancelled: promise.isCancelled(),
  isFulfilled: promise.isFulfilled(),
  isPending: promise.isPending(),
  isRejected: promise.isRejected(),
  isResolved: promise.isResolved(),
  reason: promise.isRejected() && promise.reason(),
  value: promise.isFulfilled() && promise.value(),
})

const inspectLoaders = (loaders) =>
  cloneDeepWith(loaders, (value) => {
    if (value && typeof value.then === 'function') {
      const promise = value
      return inspectPromise(promise)
    }
    return undefined
  })

function updateSingleLoader(
  prevUrl,
  nextUrl,
  prevLoader,
  nextLoaderConstructor,
) {
  if (isEqual(nextUrl, prevUrl)) {
    if (prevLoader.isCancelled()) {
      throw new Error('prevLoader isCancelled')
    }
    return prevLoader
  }
  const nextLoader = nextLoaderConstructor(nextUrl)
  if (prevLoader && prevLoader.isPending()) {
    prevLoader.cancel()
  }
  return nextLoader
}

function updateLoaderMaps(
  prevUrls,
  nextUrls,
  prevLoaders,
  nextLoaderConstructor,
) {
  const nextLoaders = mapValues(nextUrls, (nextUrl, key) => {
    const prevUrl = prevUrls[key]
    const prevLoader = prevLoaders[key]

    if (isEqual(nextUrl, prevUrl)) {
      if (prevLoader.isCancelled()) {
        throw new Error('prevLoader isCancelled')
      }
      return prevLoader
    }
    return nextLoaderConstructor(nextUrl)
  })
  cancelOldLoaders(prevLoaders, nextLoaders)
  return nextLoaders
}

export default class AbstractLoader {
  utils

  def = null
  result = null
  loaders = null

  loaderDef = null
  bluebird = null

  constructor(utils) {
    this.utils = utils
  }

  _getInternalPromise(def) {
    let loaders = this.loaders || this.utils.createLoaders(def)
    const prevDef = this.loaderDef
    if (prevDef !== null && !isEqual(def, prevDef)) {
      loaders = this.utils.updateLoaders(prevDef, def, loaders)
    }
    this.loaders = loaders

    this.loaderDef = def
    return this.utils.combineLoaders(loaders)
  }

  _getNewPromise(def) {
    if (this.def && isEqual(def, this.def)) {
      this.def = def
      return Bluebird.resolve(this.result)
    }

    return this._getInternalPromise(def).tap((result) => {
      this.result = result
      this.def = def
    })
  }

  load(def) {
    if (this.bluebird && this.bluebird.isPending()) {
      this.bluebird.cancel()
    }
    this.bluebird = this._getNewPromise(def)
    return this.bluebird
  }

  dispose() {
    if (this.bluebird && this.bluebird.isPending()) {
      this.bluebird.cancel()
    }
    this.bluebird = null
    this.def = null
    this.result = null
    this.loaderDef = null
    this.loaders = null
    this.utils = null
  }
}

export { inspectPromise, inspectLoaders, updateSingleLoader, updateLoaderMaps }
